import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'

import { Section } from '../components/section'
import Layout from '../components/layout'
import FullWidthSection from '../components/full-width-section'
import TextSection from '../components/text-section'
import TextSection2 from '../components/text-section-2'
import Grid from '../components/grid'
import GridSection from '../components/grid-section'
import CircleGrid from '../components/circle-grid'
import CircleGridSection from '../components/circle-grid-section'
import { ActionButtonOutline } from '../components/buttons'
import UList from '../components/list'
import SEO from '../components/seo'
// import Bio from '../components/bio'
import { SpacedHr, HalfSpacedHr } from '../components/spaced-hr'

import colors from '../styles/colors'

const Hero = styled.div`
  height: 100vh;
  background: #fba62f;
  margin-left: -24px;
  margin-right: -24px;
  /* background-image: url(../images/store.jpg); */
  @media (max-width: 768px) {
    margin-left: -12px;
    margin-right: -12px;
  }
`

const HeroTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 80rem;
  width: 100%;
  margin: auto;
  height: 100%;
`

const Row1 = styled.div`
  max-width: 80rem;
  width: 100%;
  margin: 3rem auto;

  @media (max-width: 80rem) {
    /* margin-left: 12px;
    margin-right: 12px; */
    margin: -2rem auto 2rem;
  }
`
const Row2 = styled.div`
  max-width: 100rem;
  width: 100%;
  margin: 3rem auto;
`

const H1 = styled.h1`
  margin: 0;
  font-weight: 800 !important;
  padding: 0 24px;
  width: 50%;
  text-transform: uppercase;
  font-size: 3rem;
  color: #15587b;
  text-shadow: 0 0 15px #10435e;
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 400px) {
    width: 90%;
    font-size: 2.5rem;
  }
  @media (max-width: 310px) {
    width: 90%;
    font-size: 2.05rem;
  }
`

const H2 = styled.h2`
  margin: 1rem 0;
  font-size: 3rem;
  font-weight: 700 !important;
`

const H3 = styled.h3`
  margin: 0;
  color: #10435e !important;
  border-bottom: none;
  font-weight: 200 !important;
  padding: 0 24px;
  width: 50%;
  color: #15587b;
  @media (max-width: 768px) {
width: 100%;
    font-size: 1.6rem;
      }
  @media (max-width: 400px) {
    width: 100%;
    font-size: 1.3rem;
  }
  @media (max-width: 310px) {
    width: 100%;
    font-size: 1.15rem;
  }
`
const H4 = styled.h4`
  margin: 0;
`

const P = styled.p`
  color: white;
  font-size: 1.35rem;
`

class IndexPage extends Component {
  render() {
    // images
    const {
      siteData,
      homepage,
      elizabeth,
      michael,
      franc,
      amanda,
      apartment,
      kitchen,
      rent1,
      rent2,
      rent3,
      rent4,
      rent5,
    } = this.props.data

    const teamImgs = [
      elizabeth.childImageSharp.fluid,
      michael.childImageSharp.fluid,
      amanda.childImageSharp.fluid,
      franc.childImageSharp.fluid,
    ]
    // bios
    // const { bios } = this.props.data
    // const team = bios.edges[0].node.team

    return (
      <Layout>
        <SEO
          title="Somali American Business Association"
          keywords={[
            `somali american`,
            `somali business`,
            `african business`,
            `somali minnesota`,
          ]}
        />
        {/* Title */}
        {/* <Section>
          <h1>{siteData.siteMetadata.title}</h1>
          <h6>
            Guiding principle: ”The glue that holds all relationships together
            is trust, and trust is based on integrity.”
          </h6>
        </Section>
        <HalfSpacedHr /> */}
        {/* Header Image */}
          {/* <FullWidthSection fluidImage={homepage.childImageSharp.fluid} /> */}
        <Hero>
          <HeroTitle>
            <H1>Let's build together!</H1>
            <H3>Cultivating collaboration, shared growth and economic success for the Somali American community through business</H3>
          </HeroTitle>
        </Hero>
        {/* About Us */}
        {/* <Row1>
          <TextSection2 color="#0094D9" header="Mission Statement">
            <P>
              <span className="highlight">
                {siteData.siteMetadata.title}&nbsp; is committed to to providing
                a secure foundation for individual growth and prosperity through
                permanent housing.
              </span>
            </P>
            <P>
              We are looking to partner with the best property owners to deliver
              a great experience for visitors to New Jersey and New York.
            </P>
            <P>
              We are interested in leasing your property to expand our Short
              Term Rental business.
            </P>
            <P>
              We are backed by the highest credit rating and a $1,000,000
              insurance policy.
            </P>
            <br />
            <blockquote>
              Our guiding principle: ”The glue that holds all relationships
              together is trust, and trust is based on integrity.”
            </blockquote>
          </TextSection2>
        </Row1> */}
        {/* <SpacedHr /> */}
        {/* Why rent? */}
        {/* <Row2>
          <h2>Why rent to us?</h2> */}
        {/* <FullWidthSection fluidImage={rent3.childImageSharp.fluid} /> */}
        {/* <Grid>
            <GridSection
              fluidImage={rent1.childImageSharp.fluid}
              underTitle="Guaranteed Rental Income"
              underText="We are a Landlord’s ideal tenant. Landlords secure the guaranteed income of a traditional lease with our corporate automatic payment system. No more late rent or worries on your mortgage payment."
            />
            <GridSection
              fluidImage={rent3.childImageSharp.fluid}
              underTitle="Professional Staff"
              underText=" Our property managers and cleaning staff report any maintenance requirements immediately using our management app, making sure that any issues are addressed professionally and timely. Less wear and tear for your property."
            />
            <GridSection
              fluidImage={rent2.childImageSharp.fluid}
              underTitle="Highly Experienced Team"
              underText="Over 20 years of experience in hospitality, finance, and technology."
            /> */}
        {/* <GridSection
            fluidImage={rent4.childImageSharp.fluid}
            underTitle="Legally Compliant"
            underText="We comply with all local laws regarding property rental and zoning, and we pay all applicable taxes."
          />
          <GridSection
            fluidImage={rent5.childImageSharp.fluid}
            underTitle="Verified Guests"
            underText="We screen each guest using machine learning technology and continually improve our fraud prevention program by partnering with leaders in the field."
          /> */}
        {/* </Grid>
        </Row2> */}
        {/* <SpacedHr />
        {/* How it works? */}
        {/* <TextSection color={colors.theme.accent} header="How it works?">
          <CircleGrid>
            <CircleGridSection
              icon={<FontAwesomeIcon icon={faPencilAlt} size="2x" />}
              underTitle="Submit Form"
              underText="Start by submitting the landlord inquiry form"
              color={colors.theme.accent}
            />
            <CircleGridSection
              icon={<FontAwesomeIcon icon={faQuoteLeft} size="2x" />}
              underTitle="Free Quote"
              underText="Our team will get in touch with you within 24 Hours"
              color={colors.theme.accent}
            />
            <CircleGridSection
              icon={<FontAwesomeIcon icon={faHome} size="2x" />}
              underTitle="Property Tour"
              underText="Schedule property tour and discuss lease terms"
              color={colors.theme.accent}
            />
            <CircleGridSection
              icon={<FontAwesomeIcon icon={faSignature} size="2x" />}
              underTitle="Sign Lease"
              underText="Sign lease agreement and we will take care of the rest!"
              color={colors.theme.accent}
            />
            <CircleGridSection
              icon={<FontAwesomeIcon icon={faMoneyCheckAlt} size="2x" />}
              underTitle="Collect Rent"
              underText="Sit back and receive ongoing rent check!"
              color={colors.theme.accent}
            />
          </CircleGrid>
          <ActionButtonOutline>
            <Link to="/contact">Contact Us</Link>
          </ActionButtonOutline>
        </TextSection>
        <SpacedHr />
        <SpacedHr /> */}
        {/* Team */}
        {/* <h2>Who are we?</h2>
        <Bio fluidPortraits={teamImgs} teamInfo={team} /> */}
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query pageQuery {
    siteData: site {
      siteMetadata {
        title
        officialTitle
      }
    }
    elizabeth: file(relativePath: { eq: "portraits/elizabeth.jpg" }) {
      ...fluidSmallImage
    }
    michael: file(relativePath: { eq: "portraits/michael.jpg" }) {
      ...fluidSmallImage
    }
    amanda: file(relativePath: { eq: "portraits/amanda.jpg" }) {
      ...fluidSmallImage
    }
    franc: file(relativePath: { eq: "portraits/francisco.jpg" }) {
      ...fluidSmallImage
    }
    homepage: file(relativePath: { eq: "misc/homepage.jpg" }) {
      ...fluidLargeImage
    }
    apartment: file(relativePath: { eq: "homes/apartment.jpg" }) {
      ...fluidLargeImage
    }
    kitchen: file(relativePath: { eq: "homes/kitchen.jpg" }) {
      ...fluidLargeImage
    }
    rent1: file(relativePath: { eq: "whyrent/rental-income.jpg" }) {
      ...fluidLargeImage
    }
    rent2: file(relativePath: { eq: "whyrent/professional-staff.jpg" }) {
      ...fluidLargeImage
    }
    rent3: file(relativePath: { eq: "whyrent/staff.jpg" }) {
      ...fluidLargeImage
    }
    rent4: file(relativePath: { eq: "whyrent/legal-compliance.jpg" }) {
      ...fluidLargeImage
    }
    rent5: file(relativePath: { eq: "whyrent/verification.jpg" }) {
      ...fluidLargeImage
    }
  }
`
